<template>
  <div
    v-if="
      singleSpeaker && singleSpeakerVideos && singleSpeakerVideos.length > 0
    "
  >
    <div
      class="ml-5"
      v-if="
        singleSpeaker && singleSpeakerVideos && singleSpeakerVideos.length > 0
      "
    >
      <SpeakerIntro :speaker="singleSpeaker" />
      <!-- <VideoPlayerPlaylist :videos="singleSpeakerVideos" v-if="singleSpeakerVideos" :key="this.$route.params.slug" /> -->
      <VideoPlayerPlaylist
        :videos="singleSpeakerVideos"
        v-if="singleSpeakerVideos"
        :key="singleSpeaker.slug"
      />
    </div>
    <div v-if="!user">
      <SpeakersSection />
      <!-- <Cta1Section /> -->
      <!-- <Cta2Section />  -->
    </div>
    <v-overlay :value="inProgress" :opacity="opacity" color="grey lighten-4">
      <h2 class="pink--text center--text">
        <span>{{ this.overlaymessage }}</span> Please wait.
      </h2>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { videosCollection} from "@/plugins/firebase.js";

export default {
  name: "SpeakerDetail",
  title: "Speaker",

  components: {
    SpeakerIntro: () => import("@/components/webapp/SpeakerIntro"),
    VideoPlayerPlaylist: () =>
      import("@/components/webapp/VideoPlayerPlaylist"),
    SpeakersSection: () => import("@/components/landing/SpeakersSection"),
    // Cta1Section: () => import("@/components/landing/Cta1Section"),
    // Cta2Section: () => import("@/components/landing/Cta2Section"),
    // TestimonialsSection: () =>
    //   import("@/components/landing/TestimonialsSection"),
  },
  data() {
    return {
      videos: [],
      inProgress: false,
      opacity: 0.9,
      overlaymessage: "Loading speaker videos",

      // singleSpeakerVideos: null,
    };
  },
  props: ["slug"],
  computed: {
    ...mapGetters({
      user: "user",
      singleSpeakerVideos: "singleSpeakerVideos",
    }),
    singleSpeaker() {
      return this.$store.getters.singleSpeaker(this.$route.params.slug);
    },
    urlSlug() {
      return this.$route.params.slug;
    },
    // singleSpeakerVideos() {
    //   return this.$store.getters.singleSpeakerVideos(this.$route.params.slug);
    // },
  },
  watch: {
    urlSlug: function () {
      console.log(this.urlSlug);
      this.fetchSpeakerVideos(this.urlSlug);
    },
  },
  methods: {
    fetchSpeakerVideos() {
      this.$store.dispatch("fetchSingleSpeakerVideos", {
        slug: this.$route.params.slug,
        user: this.user,
      });
    },
  },
  created() {
    this.fetchSpeakerVideos();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.thumbnail {
  display: flex;
}

.thumbnail img {
  width: 80px;
  height: 80px;
  min-width: 80px;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.thumbnail-info {
  margin-left: 20px;
}
</style>