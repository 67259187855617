<template>
  <div>
    <Banner />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 align="center" class="py-5">{{category}}</h1>
          <slot />
        </v-col>

        <feed-card v-for="(speaker, index) in loadedSpeakers" :key="index" :speaker="speaker" />
      </v-row>
    </v-container>
    <FooterSection />
  </div>
</template>

<script>
export default {
  name: "CategoryDetails",
  // title: `Category`,

  components: {
    FeedCard: () => import("@/components/FeedCard"),
    Banner: () => import("@/components/Banner"),
    FooterSection: () => import("@/components/landing/FooterSection"),
  },

  data: () => ({
    category: null,
  }),

  computed: {
    loadedSpeakers() {
      let speakers = this.$store.getters.loadedSpeakers;
      let filteredSpeakers = speakers.filter(speaker => speaker.areas_of_expertise.includes(this.$route.params.id));
      // console.log(filteredSpeakers)
      return filteredSpeakers
    }
  },

  mounted() {
    document.title = this.$route.params.id
  },

  created(){
    this.category = this.$route.params.id
  }
};
</script>
